import { createApp, ref } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import App from './App.vue'
import router from './router'
import store from './store'
import { useStore } from 'vuex'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import i18n from './language'
import ignore from '@/type.d'
import apiMixin from './service'
import { mixins } from 'vue-class-component'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import dayjs from "dayjs";
import './assets/iconfont/iconfont.js';
import './assets/iconfont/iconfont.css';


import VMdPreview from '@kangc/v-md-editor/lib/preview.js';
import '@kangc/v-md-editor/lib/style/preview.css';
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js';
import '@kangc/v-md-editor/lib/theme/style/github.css';
import VMdEditor from '@kangc/v-md-editor';
import '@kangc/v-md-editor/lib/style/base-editor.css';

// highlightjs
import hljs from 'highlight.js';

VMdPreview.use(githubTheme, {
  Hljs: hljs,
});
VMdEditor.use(githubTheme, {
  Hljs: hljs,
});

VMdEditor.use(githubTheme, {
  Hljs: hljs,
});

const app = createApp(App, { store }) as any;

app.use(VMdPreview);
app.use(VMdEditor);


const myMixin = {
  data() {
    return {
      store,
      api: apiMixin.api,
      foo: 'abc'
    }
  },
  methods: {
    to(path: string) {
      (this as any).$router.push(path)
    }
  }
}

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component)
}

app.mixin(myMixin)
app.use(ElementPlus, {
  locale: zhCn,
})
app.use(i18n)
app.use(store)
app.use(router)
app.mount('#app')

const dictCache:any = {};

app.config.globalProperties.$filters = {
  df(value: number | string | Date, format = 'YYYY-MM-DD') {
    if( value ) return dayjs(value).format(format);
    return null
  },
  date(value: number | string | Date) {
    return this.df(value)
  },
  month(value: number | string | Date) {
    return this.df(value, 'YYYY-MM')
  },
  age(value: number | string | Date) {
    if( value ) return - dayjs(value).diff(new Date(), 'year', false)
    return null
  },
  dict(id: number, prop = 'name') {
    if(!id) return null;
    if( dictCache[id] ) {
      return dictCache[id][prop]
    }
    const dictV = ref({} as any)
    apiMixin.api.dict.byId({ id }).then( (v: any) => dictV.value = dictCache[id] = v );
    return dictV.value[prop];
  },
  escape(str: string) {
    return str.replace(/\n/g, '<br/>')
  }
}