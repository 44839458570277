const en_US = {
  form: {
    c: {
      selectStartTime: '请选择起始时间',
      selectEndTime: '请选择结束时间',
      confirm: 'Confirm',
    },
  },
  c: {
    privacyPolicy: "Privacy Policy",
    termsOfService: "Terms of Service",
    readAndAgree: "I have read and agree to the ",
    and: ' and ',
    signOut: 'Sign out',
    signIn: 'Sign In',
    signUp: 'Sign Up',
    getCaptcha: 'Get captcha',
    regetCaptcha: '`Get captcha in ${countDown.value}s`',
    inputEmail: 'Enter your email',
    emailCheck: 'Confirm whether the email format is correct',
    inputCaptcha: 'Captcha',
    inputPassword: 'Enter a complex password with at least 8 digits',
    checkPassword: 'Enter your password',
    captchaLengthCheck: 'Confirm whether the captcha is correct',
    sureSignUp: 'Confirm',
    openEmail: 'Open email',
    copyRight: 'Copyright © 2022-present, Dudu.LTD China-Fujian ICP 2022010380-1',
    
    userCenter: 'Personal Center',
    myResume: 'My Resume',
    settings: 'Settings',

    noLimit: 'No limit',
    all: 'All',

    download: 'Download',
    features: 'Features & Specials',
    supportAndPlan: 'Support & Plan',
    bugAndRequest: 'Bug丨Discussison',
    createIssue: 'Report bug',
    createDiscussion: 'Contribute idea',
    cooperation: 'Cooperation',
    contact: 'Contact Us',

    comingSoon: 'Coming soon...',

    businessCooperation: 'Business cooperation',
    email: 'E-mail',
    contacts: 'Contacts',

    downloadRequire: 'Please sign in first so that you can get the official version authorization of the corresponding version of beta in time.',
    checkPasswordNotNull: 'Please input the password again',
    checkPasswordNotSame: 'Two inputs don\'t match!',
  },
  d: {
    corvusYe: 'Corvus Ye',
  },
  
  about: {
    'us': 'About Us',
  },

  m: {
    home: 'Home',
    plaza: 'Widget Plaza',
    products: 'Products',
    job: 'Job',
    openSource: 'Open Source',
    ngbatisDocs: 'ngbatis docs',
    ngbatis: 'ngbatis (donated)',
    waoUi: 'wao_ui',
    flutterComponents: 'flutter_components',
    flutterGraphView: 'flutter_graph_view',
    pubFlutterGraphView: 'https://pub.dev/packages/flutter_graph_view',
    documents: 'Documents',
  },
  job: {
    publish: 'Publish positions',
    companyOtherPosition: '其他在招岗位',
    delivery: '投递',
    pickResume: '选择简历',
    addCompany: '添加企业',
    enterCompanyName: '请填上企业名称 ~',
    enterCompanyEmail: '简历投递邮箱地址：hrexample.com',
    enterCompanyDescr: '企业简介写这里喔 ~',
    industry: '所属行业',
    companyDescr: '企业简介',
    companyIndex: 'Company Home',
    email: '简历投递邮箱地址',

    positionList: 'Position List',
    positionDetail: 'Position Detail',

    enterPositionName: '请输入岗位名称',
    enterPositionNo: '请输入岗位内部编码',
    enterMinSalary: '最低薪资（月）',
    enterMaxSalary: '最高薪资（月）',

    selectBase: '请选择所在地区',
    selectExp: '请选择经验要求',
    selectEdu: '请选择最低学历',
    selectNature: '请选择岗位性质',
    qBase: '所在地区',
    qExp: '经验要求',
    qEdu: '学历要求',
    qNature: '岗位性质',

    resume: {
      add: '添加简历',
      title: '我的简历',
      basicInfo: '基本信息',
      edu: '教育/培训经历',
      exp: '工作经验',
      project: '项目经验',
      evaluation: '自我评价',
    },
  },
  products: {
    graphDesk: {
      title: 'GraphDesk',
      slogan: 'Built for Graph Database',
      desc: 'A development tool tailored for graph database, which aims to be compatible with multiple data sources and help developers complete the transition to graph database.',
      version: 'community-beta (Enviroment Requirement Java 1.8+, 64 bits)',
      features: {
        f1: 'Realize local unified management of multiple data sources, and achieve instant access',
        f2: 'Store commonly used GQL, leaving brain space for life',
        f3: 'Inherit tabular thinking, step by step in habit',
        f4: 'Break the single header limit and present complex data structures',
        s1: 'Data Table',
        s2: 'Knowledge Graph Visualization',
      },
      qqGroupDownload: 'Join a group to download',
      qqGroupFeedback: 'Join a group to feedback',
    }, 
    starShadow: {
      title: 'Star Shadow',
      slogan: 'Graph Database Client',
      desc: 'A development tool tailored for graph database, <br/>which aims to be compatible with multiple data sources <br/>and strives to be an important partner for developers on the road to graph exploration.',
      version: 'v1.3.0',
      features: {
        f1: 'Realize local unified management of multiple data sources, and achieve instant access',
        f2: 'Store commonly used GQL, leaving brain space for life',
        f3: 'Inherit tabular thinking, step by step in habit',
        f4: 'Break the single header limit and present complex data structures',
        s1: 'Data Table',
        s2: 'Knowledge Graph Visualization',
      },
      qqGroupDownload: 'Join a group to download',
      qqGroupFeedback: 'Join a group to feedback',
    }
  },
  h5: {
    wechatLogin: 'Use WeChat authorization to Sign In or Sign Up',
    loginSuccess: 'Sign in success !',
    loginFailed: 'Sign in failed !',
    welcomeFollow: 'Welcome to follow our official account, so that you can contact us in time when you have any questions.',
    // 二维码已过期，请重新扫码
    failedRescan: 'The QR code has expired, please rescan',
    rescan: 'Rescan',
    // 关闭页面
    closePage: 'Close page',
  },
}

export default en_US