import { createI18n } from 'vue-i18n'
import zh_CN from './lang/zh'
import en_US from './lang/en'

export const lang = localStorage.getItem('lang') ?? 'zhCn';
export function setLang(lang: string) {
  const oldLang = localStorage.getItem('lang');
  const newLang = (lang == 'en' || lang == 'en_US') ? 'en_US' : 'zhCn'
  localStorage.setItem('lang', newLang)
  if (oldLang != newLang) {
    location.reload()
  }
} 
export const useChinese = lang == 'zhCn';

const i18n = createI18n({
  locale: useChinese ? 'zh_CN' : 'en_US',
  messages: {
    'zh_CN': zh_CN,
    'en_US': en_US
  }
})

export default i18n