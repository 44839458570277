export enum ContentType {
  RAW,
  FORM_DATA,
  MULTIPART_FORM_DATA
}

export enum RequestType {
  GET,
  POST,
  PUT,
  DELETE,
  OPTION
}

export interface Api {
  url?: string
  method?: RequestType
  param?: string
  type?: ContentType

}
