import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, resolveComponent as _resolveComponent, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_radio_group = _resolveComponent("el-radio-group")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_arrow_down = _resolveComponent("arrow-down")!
  const _component_el_icon = _resolveComponent("el-icon")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dropdown_item = _resolveComponent("el-dropdown-item")!
  const _component_el_dropdown_menu = _resolveComponent("el-dropdown-menu")!
  const _component_el_dropdown = _resolveComponent("el-dropdown")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.shape == 'radio')
      ? (_openBlock(), _createBlock(_component_el_radio_group, _mergeProps({ key: 0 }, _ctx.innerProps, {
          onChange: _ctx.update,
          modelValue: _ctx.form[_ctx.fieldName],
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form[_ctx.fieldName]) = $event)),
          class: "expanded"
        }), {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
              return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.itemProps?.component || _ctx.ElRadio), _mergeProps(_ctx.itemProps, {
                key: item.id,
                label: item.id,
                value: item.id
              }), {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(item.name), 1)
                ]),
                _: 2
              }, 1040, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 16, ["onChange", "modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.shape == 'select')
      ? (_openBlock(), _createBlock(_component_el_select, _mergeProps({ key: 1 }, _ctx.innerProps, {
          onChange: _ctx.update,
          modelValue: _ctx.form[_ctx.fieldName],
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form[_ctx.fieldName]) = $event)),
          class: "expanded"
        }), {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
              return (_openBlock(), _createBlock(_component_el_option, {
                key: item.id,
                label: item.name,
                value: item.id
              }, null, 8, ["label", "value"]))
            }), 128))
          ]),
          _: 1
        }, 16, ["onChange", "modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.shape == 'dropdown')
      ? (_openBlock(), _createBlock(_component_el_dropdown, {
          key: 2,
          style: {"width":"100%"},
          class: "expanded"
        }, {
          dropdown: _withCtx(() => [
            _createVNode(_component_el_dropdown_menu, null, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
                  return (_openBlock(), _createBlock(_component_el_dropdown_item, {
                    key: item.id,
                    label: item.name,
                    value: item.id,
                    onClick: ($event: any) => (_ctx.update(item.id))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(item.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["label", "value", "onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_el_button, {
              text: "",
              bg: "",
              style: {"width":"100%"}
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.currentDict?.name ?? _ctx.innerProps.placeholder), 1),
                _createVNode(_component_el_icon, { class: "el-icon--right" }, {
                  default: _withCtx(() => [
                    _createVNode(_component_arrow_down)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}