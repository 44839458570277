
import { BDict } from "@/model/core";
import { Options, Vue } from "vue-class-component";
import { api } from '@/service'

@Options({
  props: {
    innerProps: Object,
    form: {
      type: Object,
      require: true
    },
    first: Object,
    itemProps: Object,
    fieldName: {
      type: String,
      require: true
    },
    objFieldName: {
      type: String,
    },
    shape: {
      type: String,
      default: 'select' // select | radio | checkbox | cascader | dropdown
    },
    type: {
      type: String,
      require: true
    },
    params: {
      type: Object,
      default: undefined
    }
  }
})
export default class DictSelect extends Vue {
  shape?: string
  type?: string
  params?: any
  innerProps?: any
  itemProps?: any
  first?: BDict | undefined
  form?: any
  fieldName?: string
  objFieldName?: string
  options: Array<BDict> = []

  async load() {
    let optionsDb = await api.dict.byType({
      type: this.type,
      params: this.params
    })

    this.options.length = 0
    if(this.first) this.options.push(this.first)
    optionsDb
      .sort((a: BDict, b: BDict) => a.sort! > b.sort! ? 1 : -1)
      .forEach((e: BDict) => this.options.push(e))
  }

  get currentDict() {
    return this.form[this.objFieldName!];
  }

  update(v: number) {
    this.form[this.fieldName!] = v
    if( this.objFieldName ) {
      let dict = this.options.find( item => item.id == v)
      this.form[this.objFieldName] = dict
    }
    this.$emit('change')
  }

  async mounted() {
    await this.load();
  }
}

