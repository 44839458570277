import { ContentType, RequestType } from "@/model/api"

const apis = {
  auth: {
    token: {},
    captcha: {},
    register: {},
    login: { type: ContentType.RAW },
    wxLogin: { type: ContentType.RAW },
    openLogin: {},
    hasLogin: {},
    refresh: {},
    captchaValid: {},
    sign: {},
    refreshQrcode: {},
  },
  job: {
    list: {}
  },
  company: {
    save: { type: ContentType.RAW },
    byUser: {},
    byId: {},
    byIdWithTags: {},
  },
  position: {
    save: { type: ContentType.RAW },
    byCompanyId: {},
    page: { type: ContentType.RAW },
    detail: {},
    delivery: {},
    deliveriesById: {},
  },
  resume: {
    save: { type: ContentType.RAW },
    listByUser: {},
    saveBasicInfo: { type: ContentType.RAW },
    loadAllByUser: {},
    saveEdu: { type: ContentType.RAW },
    saveExp: { type: ContentType.RAW },
    savePro: { type: ContentType.RAW },
    saveEvaluation: { type: ContentType.RAW },
  },
  tag: {
    save: { type: ContentType.RAW }
  },
  dict: {
    byType: {},
    byId: {},
  },
  file: {
    upload: {},
    download: {
      responseType: 'blob',
      headers: { 'Content-Type': 'application/json; application/octet-stream' },
    },
    preDownload: { }
  }
}

export default apis