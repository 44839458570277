import { createStore } from 'vuex'
import server from '@/service'

const store = createStore({
  state: {
    token: '',
    user: null
  },
  getters: {
    token(state) {
      let token: string | null = state.token
      if (!token) {
        token = sessionStorage.getItem('token')
        state.token = token ?? ''
      }
      return state.token
    }
  },
  mutations: {
    changeToken(state, value) {
      state.token = value
      sessionStorage.setItem('token', value)
    }
  },
  actions: {
    async setToken(state, user) {
      const token = await server.api.auth.login(user)
      state.commit('changeToken', token)
    }
  },
  modules: {
  }
})

export default store
