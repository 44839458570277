import apis from "./apis"
import axios from "axios"
import { Result } from "@/model/core"
import { ElMessage, ElLoading } from "element-plus"
import { ContentType } from "@/model/api"

axios.interceptors.request.use(config => {
  return config
})

function complexObj(obj: any) {
  for (const p in obj) {
    if (typeof obj[p] === 'object' && p !== 'headers')
      return true
  }
  return false
}

function addContentTypeByApi(headers: any, api: any) {
  if(api.type == ContentType.RAW ) {
    headers['Content-Type'] = 'application/json'
  } else {
    headers['Content-Type'] = 'multipart/form-data'
  }
}

function addAuth(headers: any) {
  headers['auth'] = `Bearer ${sessionStorage.getItem('token')}`
  // headers['Authentication'] = `Bearer ${sessionStorage.getItem('token')}`
}

function proxy( api: any, prefix: string, suffix: string) {
  return async (param?: any, callbackOriginData = false, loading = false) => {
    let loadingEl
    if (loading) {
      loadingEl = ElLoading.service({
        lock: true,
        text: 'Loading',
        background: 'rgba(0, 0, 0, 0.7)',
      })
    }
    if (apiMixin.light == null && suffix != 'token') {
      apiMixin.light = await apiMixin.api.auth.token()
      sessionStorage.setItem('light', apiMixin.light!)
    }
    const headers = {
      light: apiMixin.light as string,
      ...api.headers
    }

    addContentTypeByApi(headers, api);
    addAuth(headers);

    const res = await axios({
      method: api.method ?? 'post',
      ...api,
      // url: `http://localhost:444/api${prefix}/${api.url ?? suffix}`,
      url: `https://dudu.ltd/api${prefix}/${api.url ?? suffix}`,
      data: param,
      
      headers: headers
    })
    loadingEl?.close()
    const result: Result = res.data

    if (callbackOriginData) return result
    
    if (result.success) {
      if (result.message) {
        ElMessage({
          showClose: true,
          message: result.message,
          type: 'success'
        })
      }
      return result.data
    } else {
      ElMessage({
        showClose: true,
        message: result.message,
        type: 'warning'
      })
      throw Error(result.message)
    }
  }
}

function toAxios(apis: any, prefix = '') {
  const axioses: any = {}
  for (const k in apis) {
    const api = apis[k]
    if (complexObj(api)) {
      axioses[k] = toAxios(api, `${prefix}/${k}`)
    } else {
      axioses[k] = proxy( api, prefix, k )
    }
  }
  return axioses
}

export class ApiMixin {
  light: string | null = null

  api: any

  data() {
    return {
      api: this.api,
      light: this.light
    }
  }

  constructor(apis: any) {
    this.api = toAxios(apis)
  }
}

const apiMixin = new ApiMixin(apis)

export default apiMixin
export const api = apiMixin.api