import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Plaza from '../views/Plaza.vue'
import Index from '../views/Index.vue'
import Job from '../views/Job.vue'
import Products from '../views/Products.vue'
import GraphDesk from '../views/products/GraphDesk.vue'
import StarShadow from '../views/products/StarShadow.vue'
import Company from '../views/job/Company.vue'
import CompanyAndPosition from '../views/job/CompanyAndPosition.vue'
import PositionDetail from '../views/job/PositionDetail.vue'
import ResumePreview from '../views/job/ResumePreview.vue'
import Home from '../views/Home.vue'
import AboutUs from '../views/AboutUs.vue'
import Login from '../views/Login.vue'
import i18n from '@/language'
const { t } = i18n.global

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'index',
    component: Index,
    redirect: 'products',
    children: [
      {
        path: '/home',
        name: 'home',
        component: Home,
        meta: {
          title: t('m.home'),
        },
      },
      {
        path: '/about',
        name: 'home',
        component: AboutUs,
        meta: {
          title: t('about.us'),
        },
      },
      {
        path: '/products',
        name: 'products',
        component: Products,
        redirect: '/products/StarShadow',
        children: [
          // {
          //   path: 'graph-desk',
          //   name: 'products-graph-desk',
          //   component: GraphDesk,
          //   meta: {
          //     title: 'GraphDesk'
          //   },
          // },
          {
            path: 'StarShadow',
            name: 'products-StarShadow',
            component: StarShadow,
            meta: {
              title: '星影'
            },
          }]
      },
      {
        path: '/plaza',
        name: 'plaza',
        component: Plaza,
        meta: {
          title: t('m.plaza'),
        },
      }, {
        path: '/job',
        name: 'job',
        component: Job,
        meta: {
          title: t('m.job')
        },
      }, {
        path: '/company',
        name: 'company',
        component: Company,
        children: [{
          path: ':id',
          name: 'company-and-position',
          component: CompanyAndPosition,
          meta: {
            title: t('job.companyIndex'),
          },
        }]
      }, {
        path: '/company/:companyId/position/:positionId',
        name: 'position-detail',
        component: PositionDetail,
        meta: {
          title: t('job.positionDetail'),
        },
      }, {
        path: '/resume',
        name: 'resume',
        component: ResumePreview,
        meta: {
          title: t('c.myResume')
        },
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: `${t('c.signIn')} | ${t('c.signUp')}`
    },
  },
  {
    path: '/loginWx',
    name: 'loginWx',
    component: () => import('@/views/LoginWx.vue'),
  },
  {
    path: '/loginWxLoading',
    name: 'loginWxLoading',
    component: () => import('@/views/LoginWxLoading.vue'),
  },
  {
    path: '/h5/login',
    name: 'h5-login',
    component: () => import('@/views/h5/Login.vue'),
  },
  {
    path: '/h5/success',
    name: 'h5-success',
    component: () => import('@/views/h5/Success.vue'),
  },
  {
    path: '/h5/failed',
    name: 'h5-failed',
    component: () => import('@/views/h5/Failed.vue'),
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) {
    //判断是否有标题
    console.log(to.meta.title)
    document.title = to.meta.title + ' - 笃笃科技'
  } else {
    document.title = '笃笃科技'
  }
  next()
})

export default router
